/* rewrite the ant4 design form label style */
:global(.ant4-form-item-label label) {
  color: #525252;
  font-weight: normal;
}
:global(.ant4-form-item-label label:before) {
  content: '';
  /* remove the red asterisk （*）*/
}
:global(li.ant4-menu-item.WS-menu-multipleline) {
  height: auto !important;
  line-height: 16px !important;
  min-height: 42px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  white-space: pre-wrap !important;
  display: flex;
  align-items: center;
}
:global(.ant4-table-fixed-left) {
  box-shadow: 2px 1px 12px rgba(0, 0, 0, 0.1);
}
:global(.ant4-table-fixed-right) {
  box-shadow: -2px 1px 12px rgba(0, 0, 0, 0.1);
}
:global(.WS-bordered-table .ant4-table-placeholder) {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}
:global(.WS-checkbox-large .ant4-checkbox-inner) {
  width: 20px;
  height: 20px;
}
:global(.WS-checkbox-large .ant4-checkbox-inner:after) {
  left: 6px;
  top: 2px;
  width: 7.5px;
  height: 12px;
}
:global(.WS-AnalyticsFilter__Select .ant4-select-selection__rendered) {
  display: none;
}
:global(.WS-AnalyticsFilter__Select .ant4-select-selection--multiple) {
  cursor: pointer;
}
:global(.WS-AnalyticsFilter__Select .ant4-select-search__field__placeholder) {
  display: block !important;
  color: #525252;
  font-weight: 600;
  left: 20%;
  cursor: pointer;
}
:global(.WS-AnalyticsFilter__Select-Popup .ant4-select-tree li ul) {
  padding: 0;
}
:global(.WS-table .ant4-table-thead > tr > th) {
  background: none;
  border-bottom: 1px solid #e9e9e9;
}
:global(.WS-components-table .ant4-table-expanded-row) {
  background: #ffffff;
}
:global(.WS-components-table .ant4-table-expanded-row > td:last-child) {
  padding: 0 0 0 0;
}
:global(.WS-components-table .ant4-table-expanded-row .ant4-table-row:last-child) {
  border: none;
}
:global(.WS-components-table .ant4-table-thead > tr > th) {
  background: none;
  border-bottom: 1px solid #e9e9e9;
}
:global(.WS-hide-table-expand .ant4-table-row-expand-icon) {
  display: none;
}
:global(.WS-components-table-row .ant4-table-row-expand-icon-cell) {
  position: relative;
}
:global(.WS-components-table-row .ant4-table-row-expand-icon) {
  border: 0;
  background-color: transparent;
}
:global(.WS-components-table .ant4-table-small) {
  border: none;
  border-radius: 0;
  /*width: 488px*/
}
:global(.WS-components-table .ant4-table-small .ant4-table-body > table) {
  border: none;
  padding: 0;
}
:global(.WS-components-table .ant4-table-small .ant4-table-tbody > tr > td) {
  padding: 7px 8px;
}
:global(.WS-components-table .ant4-table-expanded-row:hover > td) {
  background: #ffffff;
}
:global(.WS-components-table-row .ant4-table-row-collapsed:after) {
  content: url('./assets/down-arrow.svg');
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  /* right: 16px; */
  top: 0;
  display: inline-block;
  transform: rotate(0deg);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
:global(.WS-components-table-row .ant4-table-row-expanded:after) {
  content: url('./assets/down-arrow.svg');
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  /* right: 16px; */
  top: 0;
  display: inline-block;
  transform: rotate(180deg) translateY(-2px);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
:global(.custom-collapse .ant4-collapse-header) {
  padding-left: 0px !important;
  font-size: 14px !important;
  font-weight: 600;
}
:global(.custom-collapse .ant4-collapse-content) {
  padding-left: 0px !important;
}
/* for Radio.Group in application form editing  */
:global(.WS-radio-group-editing .ant4-radio-group) {
  display: flex;
  flex-direction: column;
}
:global(.WS-radio-group-editing .ant4-radio-group .ant4-radio-wrapper) {
  display: flex;
  align-items: center;
}
:global(.WS-checkbox-group-editing .ant4-checkbox-group) {
  display: flex;
  flex-direction: column;
}
:global(.WS-checkbox-group-editing .ant4-checkbox-group .ant4-checkbox-wrapper) {
  display: flex;
  align-items: center;
}
/* fix multiple line label text line-heigh too large issue */
:global(.WS-checkbox-group-editing .ant4-checkbox-group .ant4-checkbox-wrapper span) {
  line-height: 18px;
}
:global(.WS-checkbox-group-editing .ant4-checkbox-group .ant4-checkbox-wrapper:not(:last-child)) {
  margin-bottom: 13px;
}
:global(.WS-antd-menu-no-right-border.ant4-menu) {
  border-right-color: transparent;
}
/* re-write Steps.step current step text color */
:global(.ant4-steps .ant4-steps-item.ant4-steps-status-process .ant4-steps-title) {
  color: #0d8ce6;
}
/* remove antd input :focus shadow */
:global(.WS-input-no-focus-shadow.ant4-input:focus) {
  box-shadow: none;
}
:global(.custom-checkbox-group .ant4-checkbox-group-item) {
  display: block;
}
:global(.ant4-collapse > .ant4-collapse-item.schedule-advanced-settings > .ant4-collapse-header) {
  color: #525252;
}
/* modify antd primary button style a little bit */
:global(.ant4-btn-primary) {
  background: #0d8ce6;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}
:global(.ant4-btn-primary:not(.ant4-btn-circle)) {
  border-radius: 4px;
}
/* Manually display stage id errors on top */
:global(.scheduling-stage-ids .has-error .ant4-form-explain) {
  display: none;
}
/* Scheduling Settings */
:global(.scheduleSettingsInputAddon + .ant4-input-group-addon) {
  width: 80px;
  background-color: #ebf1f9;
}
:global(.ant4-select-tree li ul) {
  padding-left: 6px;
}
:global(.WS-flex-spinner .ant4-spin-container) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
}
/* Adjust table select column width from 62 to 48px */
:global(.ant4-table-thead > tr > th.ant4-table-selection-column),
:global(.ant4-table-tbody > tr > td.ant4-table-selection-column) {
  min-width: 48px !important;
  width: 48px !important;
}
/* Removes the default border on the left and right of fixed tables */
:global(.ant4-table-fixed-left .ant4-table-fixed) {
  border-right: none !important;
}
:global(.ant4-table-fixed-right .ant4-table-fixed) {
  border-left: none !important;
}
/* antd modal */
:global(.WS-modal-body-share-position .ant4-modal-title) {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
}
:global(.WS-modal-body-share-position .ant4-modal-content) {
  background-color: #f5f6fa;
}
:global(.WS-modal-body-share-position .ant4-modal-body) {
  padding: 0;
}
:global(.WS-modal-body-no-padding .ant4-modal-body) {
  padding: 0;
}
:global(.WS-modal-body-no-padding .ant4-modal-body) {
  height: 100%;
}
:global(.WS-modal-body-no-padding .ant4-modal-body) {
  height: 100%;
}
/* antd Menu (use the menu item selected style when mouse hovering, Phil ask for it WS-790)  */
:global(.ant4-menu-item-active),
:global(.ant4-menu-submenu-active) {
  background-color: #ecf6fd;
}
/* change default padding to margin, to prevent underline highlight from showing in padded area */
:global(.ant4-menu-horizontal > .ant4-menu-item) {
  margin: -1px 0 0 32px;
  padding: 0;
}
/* antd Switch: change the font-size to smaller one and move text a little bit up to vertical centering */
:global(.ant4-switch-inner) {
  font-size: 10px;
  margin-top: -1px;
}
/* change the default antd menu color change time: from 0.3s to 0.1s */
:global(.ant4-menu-item) {
  /* original */
  /* transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) */
  /* changed */
  transition: color 0s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0s cubic-bezier(0.645, 0.045, 0.355, 1);
}
/* Making more room for the prefix component for the input componet */
:global(.WS-ant4-input-padding-50 input) {
  padding-left: 50px !important;
}
/* search box in schedule page */
:global(.WS-schedule-stage-search .ant4-input) {
  border-radius: 17px;
  border-color: #e6eaf2;
}
:global(.shadowTable .ant4-pagination.ant4-table-pagination) {
  display: none;
}
:global(.ant4-modal-mask) {
  background-color: rgba(1, 33, 56, 0.5) !important;
}
:global(.WS-schedule-stage-search.ant4-input-affix-wrapper .ant4-input:not(:first-child)) {
  padding-left: 32px;
}
:global(.WS-schedule-stage-tree li span.ant4-tree-switcher) {
  color: #bfbfbf;
}
:global(.WS-schedule-stage-tree li span.ant4-tree-switcher:after) {
  font-size: 16px !important;
}
:global(.WS-schedule-stage-tree.ant4-tree li .ant4-tree-node-content-wrapper) {
  width: 84%;
}
:global(.WS-schedule-stage-tree.ant4-tree li span.ant4-tree-checkbox) {
  margin-top: 3px;
}
:global(.WS-schedule-stage-tree.ant4-tree li.WS-positions-stage-node span.ant4-tree-checkbox) {
  margin-top: 5px;
  vertical-align: top;
}
/* Adjust padding of locations */
:global(.WS-schedule-stage-tree.ant4-tree .WS-positions-location-node) {
  padding-top: 2px;
}
/* Adjust padding of 1st stage of each location */
:global(.WS-schedule-stage-tree.ant4-tree .WS-positions-stage-node:first-child) {
  padding-top: 8px;
}
/* Adjust padding of subsequent stages of each location */
:global(.WS-schedule-stage-tree.ant4-tree .WS-positions-stage-node) {
  padding-top: 0;
}
/* rewrite disabled input background base on Phil's design */
:global(.ant4-input-disabled) {
  background-color: #f6f8f9;
}
:global(.ant4-select-disabled .ant4-select-selection) {
  background-color: #f6f8f9;
}
/* manage user modal input */
:global(.ant4-form-item-label label) {
  letter-spacing: 0;
}
:global(.ant4-form-item-label label.ant4-form-item-required:before) {
  display: none;
}
:global(.WS-ApplicantsFilter__Select .ant4-select-selection--multiple) {
  min-height: 33px;
  cursor: pointer;
}
:global(.WS-ApplicantsFilter__Select .ant4-select-search__field__placeholder) {
  font-size: 14px;
  color: #525252;
  top: 16px;
  left: 10%;
  height: 19px;
}
/* Customize position template search input margins */
:global(.WS-position-template-search .ant4-input-prefix) {
  left: 12px;
}
:global(.WS-position-template-search .ant4-input:not(:first-child)) {
  padding-left: 37px;
}
:global(.WS-full-height-spin .ant4-spin-container) {
  height: 100%;
}
:global(.WS-auto-width-spin .ant4-spin-container) {
  width: auto !important;
}
/* Change default disabled style */
:global(.ant4-btn-primary.WS-button.disabled),
:global(.ant4-btn-primary.WS-button[disabled]) {
  opacity: 0.3;
  background: #0d8ce6;
  border-color: #0d8ce6;
}
:global(.applicantsPopover .ant4-popover-inner-content) {
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
}
/* custom the Step style in offer stage UploadFile component */
:global(.WS-steps .ant4-steps .ant4-steps-item.ant4-steps-status-finish .ant4-steps-head-inner) {
  border-color: #45cf86;
  background-color: transparent;
}
:global(.WS-steps .ant4-steps .ant4-steps-item.ant4-steps-status-process .ant4-steps-head-inner) {
  border-color: #45cf86;
  background-color: #45cf86;
}
:global(.WS-steps .ant4-steps .ant4-steps-item.ant4-steps-status-finish .ant4-steps-head-inner > .ant4-steps-icon) {
  color: #45cf86;
}
:global(.WS-steps .ant4-steps .ant4-steps-item.ant4-steps-status-finish .ant4-steps-tail > i:after) {
  background-color: #e6eaee;
  background: #e6eaee;
  transition: none;
  box-shadow: none;
  animation: none;
}
:global(.ant4-collapse) {
  border: 0px;
  background-color: #fff;
}
:global(.ant4-collapse > .ant4-collapse-item) {
  border-bottom: 0px;
}
:global(:root .ant4-collapse > .ant4-collapse-item > .ant4-collapse-header[aria-expanded='true'] .arrow) {
  font-size: 16px;
  color: #b9c2cb;
}
:global(.ant4-collapse > .ant4-collapse-item > .ant4-collapse-header) {
  color: #b9c2cb;
  font-size: 14px;
}
:global(.ant4-form-item-label label:after) {
  display: none;
}
:global(.ant4-switch-disabled) {
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.25);
}
:global(.ant4-switch-disabled:after) {
  background: #fff;
}
:global(.ant4-switch-checked.ant4-switch-disabled) {
  background: #108ee9;
}
/* Change default switch style */
:global(.WS-switch.ant4-switch) {
  min-width: 40px;
  background-color: #e3e3e3;
}
:global(.WS-switch.ant4-switch-checked) {
  background-color: #dcecf6;
}
:global(.WS-switch.ant4-switch-checked:after) {
  background-color: #0d8ce6;
}
/* Custom ghost button for EditPosition */
:global(.WS-ghost-published.ant4-btn:hover),
:global(.WS-ghost-unpublished.ant4-btn:hover),
:global(.WS-ghost-published.ant4-btn:focus),
:global(.WS-ghost-unpublished.ant4-btn:focus),
:global(.WS-ghost-published.ant4-btn:active),
:global(.WS-ghost-unpublished.ant4-btn:active) {
  color: #ffffff4a;
  border-color: #ffffff4a;
}
:global(.WS-left-open-submenu > .ant4-dropdown-menu) {
  left: auto;
  right: 100%;
}
:global(.WS-no-padding-submenu-title .ant4-dropdown-menu-submenu-title) {
  padding: 0 !important;
  position: relative;
}
:global(.WS-no-padding-submenu-title .ant4-dropdown-menu-submenu-title .ant4-dropdown-menu-submenu-expand-icon) {
  top: 4px;
}
:global(.WS-scrollTable-right .ant4-table) {
  border-radius: 0px 4px 0 0;
}
:global(li.ant4-menu-item.WS-new-message) {
  padding-left: 16px !important;
  height: 56px;
  line-height: 56px;
}
:global(li.ant4-menu-item.WS-new-message:after) {
  padding-left: 16px !important;
  height: 56px;
  line-height: 56px;
  border-right: 2px solid #108ee9;
}
/* hide the loading modal comfirmation button when user click 'edit' after multiple checked events */
:global(.WS-no-footer-info-modal .ant4-confirm-btns) {
  display: none;
}
:global(.ant4-calendar-input-wrap) {
  display: none;
}
:global(.ant4-modal-centered) {
  text-align: center;
}
:global(.ant4-modal-centered::before) {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
:global(.ant4-modal-centered .ant4-modal) {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
:global(.ant4-confirm-body .ant4-confirm-content) {
  word-break: break-word;
}
:global(.WS-table-no-wrap .ant4-table-thead > tr > th) {
  word-break: initial;
  white-space: nowrap;
}
:global(.WS-table-no-wrap .ant4-table-tbody > tr > td) {
  word-break: initial;
  white-space: nowrap;
}
:global(.ant4-btn.WS-transparent-button) {
  border: 1px solid #ffffff;
  background-color: transparent;
  height: 37px;
}
:global(.ant4-btn.WS-transparent-button:hover),
:global(.ant4-btn.WS-transparent-button:focus) {
  background-color: rgba(103, 103, 103, 0.37);
}
:global(.WS-vertical-center-modal .ant4-modal) {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}
/* In our design, the ant4 form label is always 13px */
:global(.ant4-form-item-label) {
  font-size: 13px;
}
:global(.WS-no-checkmark-dropdown .ant4-select-dropdown-menu-item:hover::after) {
  display: none;
}
:global(.WS-disabled-select .ant4-select-selection) {
  background-color: white;
  cursor: default;
}
:global(.drop-down-menu-wrapper .ant4-popover-arrow) {
  display: none;
}
:global(.drop-down-menu-wrapper .ant4-popover-inner) {
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}
:global(.ant4-select .ant4-select-selection[role='combobox'] .ant4-select-selection__rendered ul) {
  margin-bottom: 0;
}
:global(.ws-ant4-menu-submenu-active) {
  background-color: #ecf6fd;
}

/* Show children of matched locations */
:global(.WS-schedule-stage-tree.ant4-tree
    li.WS-positions-location-node.WS-show-children
    li.WS-positions-stage-node.WS-display-none) {
  display: block;
}

:global(.applicantsPopover.ant4-popover-placement-bottom
    > .ant4-popover-content
    > .ant4-popover-arrow:after, .applicantsPopover.ant4-popover-placement-bottomLeft
    > .ant4-popover-content
    > .ant4-popover-arrow:after, .applicantsPopover.ant4-popover-placement-bottomRight
    > .ant4-popover-content
    > .ant4-popover-arrow:after) {
  border-bottom-color: rgba(0, 0, 0, 0.75);
}

:global(.workstream-no-hpadding .ant4-popover-inner-content) {
  /* over write the ant4 design popover style */
  /* original value is 8px 15px */
  padding: 8px 0px;
}