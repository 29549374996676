html,
body,
:global(#subapp_root_hr) {
  height: 100%;
  background-color: #f5f6fa;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global(body p),
:global(body h1),
:global(body h2),
:global(body h3),
:global(body h4),
:global(body h5),
:global(body h6) {
  margin-bottom: 0;
}

:global(.pb1) {
  padding-bottom: 8px;
}

:global(.pb2) {
  padding-bottom: 16px;
}

:global(.pb3) {
  padding-bottom: 24px;
}

:global(.tc) {
  text-align: center;
}

:global(#nprogress .bar) {
  background: #0d8ce6;
  position: fixed;
  z-index: 1024;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
}

:global(#nprogress .peg) {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #0d8ce6, 0 0 5px #0d8ce6;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

:global(#nprogress .spinner) {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

:global(#nprogress .spinner-icon) {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #0d8ce6;
  border-left-color: #0d8ce6;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

:global(#nprogress .nprogress-custom-parent) {
  overflow: hidden;
  position: relative;

  #nprogress {
    .bar,
    .spinner {
      position: absolute;
    }
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

:global(.sortableHelper) {
  z-index: 10001;
}

:global(textarea) {
  resize: vertical;
  padding: 5px;
}

:global(.WS-bordered-table table) {
  border: 1px solid #e6e6e6;
  table-layout: fixed;
}

:global(.WS-bordered-table tr:last-child > td) {
  border: none;
}

:global(.WS-bordered-table tr:last-child > td) {
  border: none;
}

:global(.WS-bordered-table thead th) {
  border-bottom: 1px solid #e6eaee;
  background-color: #f2f3f9;
}

:global(.WS-left-pad-column) {
  padding-left: 20px !important;
}

:global(.WS-MCQOption-input input[type='text']) {
  padding-right: 100px !important;
}

:global(.WS-no-outline:focus) {
  box-shadow: none;
  outline: none;
}

:global(.WS-offer-template-textarea) {
  min-height: 98px;
}

/* offer template editor */
:global(.WS-offer-template-editor) {
  padding: 10px;
}

:global(.WS-offer-template-editor:focus) {
  box-shadow: none;
  outline: none;
}

:global(.WS-offer-template-editor .token-span) {
  background-color: #eff1fc;
  color: black;
  font-weight: 600;
  border-radius: 5px;
}

:global(.WS-offer-letter-preview p) {
  line-height: 24px;
  font-size: 14px;
  margin: 24px 0;
}

:global(.WS-merge-tag-editable) {
  font-size: 13px;
  color: #525252;
  padding: 16px;
  margin: 0;
  word-break: break-word;
  white-space: normal;
}

:global(.WS-merge-tag-editable .WS-merge-tag) {
  border-radius: 2px;
  padding: 2px;
  margin: 0 1px;
  color: #525252;
  background-color: #eff1fc;
}

:global(.WS-merge-tag-editable .WS-merge-tag-invalid) {
  border-radius: 2px;
  padding: 2px;
  margin: 0 1px;
  color: #f84848;
  background-color: #ffe3e3;
}

:global(.WS-jobboard-tooltip) {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

:global(.WS-jobboard-tooltip-wrapper:hover .WS-jobboard-tooltip) {
  display: block;
  opacity: 1;
}

:global(.WS-Analytics-Chart-Title) {
  font-weight: 600;
  cursor: auto;
  color: #525252 !important;
}

:global(g.recharts-cartesian-axis.recharts-yAxis.yAxis text) {
  text-anchor: middle;
}

:global(.WS-sortable-table-column) {
  cursor: pointer;
}

:global(.intl-tel-input) {
  width: 100%;
}

:global(.WS-static-row-height) {
  vertical-align: top;
}

:global(.WS-notification-slide-panel .slide-pane__header) {
  display: none;
}

:global(.WS-notification-slide-panel .slide-pane__content) {
  padding: 0px;
  background-color: #f5f6fa;
}

:global(.ReactModal__Overlay) {
  z-index: 1000;
}

:global(.slide-pane__overlay) {
  z-index: 1000;
}

:global(.slide-pane__overlay.ReactModal__Overlay--after-open.applicants-filter-slide-panel) {
  background-color: transparent !important;
  z-index: 999;
}

:global(.WS-jobboard-note) {
  background: #fffaeb;
  border: 1px solid #ffe9a8;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  position: relative;
}

:global(.WS-jobboard-note:before) {
  /*content: '';*/
  /*display: block;*/
  /*position: absolute;*/
  /*right: 25px;*/
  /*bottom: 100%;*/
  /*width: 0;*/
  /*height: 0;*/
  /*border: 10px solid transparent;*/
  /*border-bottom-color: #FFE9A8;*/
}

:global(.WS-jobboard-note:after) {
  /*content: '';*/
  /*display: block;*/
  /*position: absolute;*/
  /*right: 26px;*/
  /*bottom: 100%;*/
  /*width: 0;*/
  /*height: 0;*/
  /*border: 9px solid transparent;*/
  /*border-bottom-color: #FFFAEB;*/
}

/* Rewrite ant design button to make it borderless */
:global(.WS-text-button) {
  border: none;
  background: transparent !important;
}

:global(.WS-text-button:hover, .WS-text-button:active, .WS-text-button:focus, .WS-text-button:visited, ) {
  border: none;
  background: transparent !important;
}

/* sidebar position/department edit button */
:global(.WS-sidebar-edit-button-container .WS-sidebar-edit-button) {
  display: flex;
  margin-left: 2px;
  visibility: hidden;
}

:global(.WS-sidebar-edit-button-container:hover .WS-sidebar-edit-button) {
  visibility: visible;
}

/*  */
.mb0 {
  margin-bottom: 0;
}

/* Change style of Collapse */
:global(.custom-collapse i.arrow) {
  right: 0px;
  left: auto !important;
}

/* util class name to define an element that appears when hover on it's container */
:global(.WS-hover-container .WS-hover-content) {
  visibility: hidden;
}

:global(.WS-hover-container:hover .WS-hover-content) {
  visibility: visible;
}

/* prevent Rich Text Editor's line-height been re-write when nested in ant form item */
:global(.public-DraftEditor-content) {
  line-height: 1.5;
}

:global(.WS-positions-sidebar-search input) {
  border: none;
  padding-left: 28px !important;
}

:global(.scheduleSettingsInputAddon) {
  border-right: none;
}

:global(.custom-question-column) {
  max-width: 200px;
  min-width: 100px;
}

:global(.custom-answer-column) {
  display: inline-block;
  max-width: 200px;
  min-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

:global(.WS-filter-slide-panel-overlay) {
  left: 0 !important;
  background-color: transparent !important;
}

:global(.WS-flex-spinner),

:global(.scheduleTimeColumn span) {
  display: flex;
  align-items: center;
}

:global(.WS-time-filter-hidden) {
  width: 0;
  height: 0;
  overflow: hidden;
}

:global(.WS-date-filter-modal) {
  position: fixed;
  z-index: 1000;
}

:global(.WS-scheduling-filter-icon) {
  width: 41px;
  height: 27px;
  border-radius: 13.6px;
  background-color: #e5e9f5;
}

:global(.WS-scheduling-filter-icon:hover) {
  background-color: #dadeeb;
  cursor: pointer;
}

/* some utility class name */
:global(.unselectable) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:global(.h34px) {
  height: 34px;
}

:global(.w220px) {
  width: 220px;
}

/* animate bulk edit hint panel */
:global(.disappear-left-360) {
  width: 360px;
}

:global(.disappear-left-360-exit) {
  opacity: 1;
  width: 360px;
}

:global(.disappear-left-360-exit-active) {
  opacity: 0.01;
  width: 0px;
  transition: all 700ms ease;
}

/* Fixed table header */
:global(.shadowTable tbody) {
  visibility: collapse;
}

:global(.slide-pane__overlay.ReactModal__Overlay--after-open) {
  background-color: rgba(1, 33, 56, 0.5) !important;
}

/* end - search box in schedule page */

:global(.WS-display-none) {
  display: none;
}

/* end - manage user modal input */

/* Rich editor*/
:global(.RichTextEditor__editor___1QqIU .RichTextEditor__paragraph___3NTf9) {
  margin: 0px !important;
}

/* ms login button */
:global(button.btn-microsoft-login) {
  border: none;
  background: none;
  cursor: pointer;
}

/* Bulk edit confirmation page actions table */
:global(.WS-BE-actions-table th) {
  padding-left: 27px !important;
}

:global(.WS-BE-actions-table td) {
  padding: 0 !important;
  vertical-align: top !important;
}

:global(.WS-full-height-spin) {
  height: 100%;
}

/* Animate close circle */
:global(.anticon-close-circle) {
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
  font-size: 12px;
}

:global(.anticon-close-circle:hover) {
  color: #999;
}

:global(.ant4-notification-notice-close-icon svg) {
  display: none;
}

:global(.design-system-notification .ant4-notification-notice-with-icon) {
  display: flex;
  align-items: center;
}

:global(.design-system-notification .ant4-notification-notice-with-icon .ant4-notification-notice-message) {
  margin-bottom: 0;
  font-size: 14px;
  padding-right: 0;
  color: #27272a;
}

:global(.design-system-notification .ant4-notification-notice-close .ant4-notification-notice-close-icon::before) {
  color: #27272a;
  font-size: 12px;
}

:global(.design-system-notification .ant4-notification-notice-close) {
  top: 21px;
}

/* Add right margin only for JD inputs on the left */
:global(.WS-JD-input:nth-child(odd)) {
  /* margin-right: 40px; */
}

:global(div.arrow) {
  width: calc(100% + 32px);
  margin-left: -16px;
  height: 1px;
  background: #e6eaee;
  position: relative;
  margin-top: 15px;
}

:global(div.arrow:after) {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -14px;
  left: 5%;
}

:global(div.arrow:before) {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #e6eaee transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 5%;
}

/* reset password input */
:global(.WS-reset-password-email input) {
  padding-left: 30px !important;
}

/* merge tag editor begin */
:global(.hide-toolbar) {
  display: none;
}

:global(.mte-subject .public-DraftStyleDefault-block) {
  overflow-x: auto;
  white-space: pre;
  padding: 5px 0px;
}

:global(.mte-subject .public-DraftEditor-content) {
  padding: 0px !important;
}

/* :global(.mte-body .DraftEditor-root) {
  padding: 15px 8px 0px;
} */

:global(.mte-body .public-DraftEditor-content) {
  min-height: 100px;
}

:global(.mte-body .mte-merge-tag) {
  display: inline-block;
}

:global(.mte-merge-tag svg) {
  vertical-align: middle;
}

/* :global(.mte-referral-body .DraftEditor-root) {
  padding: 0;
} */

:global(.mte-referral-body .public-DraftEditor-content) {
  min-height: 100px;
}

:global(.mte-referral-body .mte-merge-tag) {
  display: inline-block;
}

/* :global(.mte-custom-toolbar) {
  margin: 0px !important;
  padding: 0px 0px !important;
  background: #f2f3f9;
  border-bottom: 1px solid #e6eaee !important;
} */

:global(.mte-custom-toolbar > div) {
  margin-bottom: 0px !important;
  height: 30px;
}

:global(.mte-custom-toolbar button) {
  border-radius: 0px !important;
  background: transparent;
  border: 0px !important;
  padding-left: 5px;
  padding-right: 5px;
}

:global(.mte-custom-toolbar button > span) {
  background-size: 20px !important;
}

:global(.mte-custom-toolbar .custom-toolbar-btn[class*='isActive']) {
  background-color: #cccccc;
}

:global(.mte-custom-toolbar .custom-toolbar-btn span) {
  background-size: 24px !important;
}

:global(.mte-custom-toolbar .custom-toolbar-btn span) {
  background-size: 24px !important;
}

:global(.triangle-down) {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: #e6eaee transparent transparent transparent;
  line-height: 0;
}

:global(.triangle-down:before) {
  content: '';
  position: absolute;
  top: -10px;
  left: -9px;
  border-top: 9px solid #f2f3f9;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

/* merge tag editor end */

:global(.WS-no-padding-submenu-title) {
  padding: 0 !important;
  position: relative;
}

/* More obvious scroll table column in OfferStageCard.js */
:global(.ScrollbarsCustom-TrackX) {
  background: #f3f2f8 !important;
  width: 100% !important;
  left: 0 !important;
}

:global(.ScrollbarsCustom-ThumbX) {
  background: #148ee4 !important;
}

:global(.WS-scrollTable-right table) {
  border-left: none !important;
  border-radius: 0px 4px 0 0;
}

/* messages */
:global(.WS-message-header-show) {
  padding: 15px 16px;
  opacity: 1;
  height: auto;
}

:global(.WS-message-header-hide) {
  transition: height 0.1s ease-in-out;
  opacity: 0;
  height: 0;
  padding: 0 !important;
}

:global(.WS-message-item) {
  position: relative;
}

:global(.WS-message-item.active:after) {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 2px solid #108ee9;
}

/* Demo: Aug 6, 2019 Full Calendar Style Customization */
/* ------------------------------------------------------------------------- */
:global(.fc-unthemed td.fc-today) {
  background-color: #f5f6fa !important;
  border-left-color: #e6eaee;
}

:global(tbody tr:first-child .fc-axis.fc-time.fc-widget-content) {
  position: relative;
  top: -10px;
}

:global(tbody tr:not(:first-child) .fc-axis.fc-time.fc-widget-content) {
  position: relative;
  top: -18px;
}

:global(.fc-time-grid .fc-slats td) {
  height: 32px !important;
}

:global(.WS-fc-column-header) {
  /* margin: 8px 0 8px 16px; */
  cursor: pointer;
  text-align: left;
}

:global(.WS-fc-column-header--day) {
  font-size: 12px;
  color: #666;
  font-weight: normal;
}

:global(.WS-fc-column-header--date) {
  font-size: 50px;
  font-weight: 600;
  line-height: 1;
  color: #666;
}

:global(.fc-toolbar.fc-header-toolbar) {
  height: 0px;
  margin-bottom: 0px !important;
}

:global(.fc-time-grid .fc-slats .fc-minor td) {
  border-color: transparent !important;
}

:global(.fc-unthemed td.fc-widget-content) {
  border-color: #e6eaee !important;
}

:global(td.fc-widget-content) {
  border-left-color: transparent !important;
}

:global(.fc-unthemed .fc-axis.fc-time.fc-widget-content) {
  border-top-color: transparent !important;
}

:global(td.fc-head-container.fc-widget-header) {
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

:global(.WS-event .WS-event-content) {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: 2px;
}

/* creating state event */

/* wizard */
:global(.wizard-step) {
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background-color: #f1f1f1;
  display: inline-block;
  margin-right: 5px;
}

:global(.wizard-step.active) {
  background-color: #0d8ce6;
}

:global(.gray-line) {
  margin: 0 auto;
  width: 225px;
  height: 1px;
  border: solid 1px rgba(215, 215, 215, 0.52);
}

:global(.WS-copy-box) {
  background: rgba(6, 6, 6, 0.292778);
  border-radius: 4px;
  height: 37px;
}

:global(.WS-copy-box ::selection) {
  background: none;
}

:global(.WS-vertical-center-modal) {
  text-align: center;
  white-space: nowrap;
}

:global(.WS-vertical-center-modal:before) {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

:global(.slide-pane_from_left.ReactModal__Content--after-open) {
  transform: translateX(20%);
}

:global(.WS-position-manager-dropdown-item:hover) {
  font-weight: 600;
}

:global(.WS-brand-suffix-for-location) {
  margin-left: 8px !important;
}

:global(.improved-scheduling .fc-event) {
  /*  the calendar CSS load after index.css, so need to use important */
  background-color: transparent !important;
}

:global(.item-signer-prepare-hs-modal-enter) {
  opacity: 0;
}

:global(.item-signer-prepare-hs-modal-enter-active) {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

:global(.item-signer-prepare-hs-modal-exit) {
  height: 100px;
  opacity: 0;
}

:global(.item-signer-prepare-hs-modal-exit-active) {
  height: 0px;
  transition: height 300ms ease-out;
}

/* CSSTransition animation class `.section` */
:global(.brand-section-enter) {
  opacity: 0;
  transform: scale(0.9);
}

:global(.brand-section-enter-active) {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

:global(.brand-section-exit) {
  opacity: 1;
}

:global(.brand-section-exit-active) {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* PopOver Overwrites */

:global(.dropdown-option) {
  height: 44px;
  width: 200px;
  border-radius: 0px;
  padding: 12px 16px 12px 16px;
}

:global(.dropdown-option:hover) {
  background: #f9fafb;
  cursor: pointer;
}

:global(.nfw-toast.ant4-notification-notice) {
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.12);
  padding: 20px;
}

:global(.info-nfw-toast .ant4-notification-notice-icon) {
  color: #3fabf8;
}

:global(.warning-nfw-toast .ant4-notification-notice-icon) {
  color: #ffc909;
}

:global(.success-nfw-toast .ant4-notification-notice-icon) {
  color: #75bd5e;
}

:global(.error-nfw-toast .ant4-notification-notice-icon) {
  color: #ff7152;
}

:global(.nfw-toast .ant4-notification-notice-close-x) {
  color: #27272a;
}

:global(.nfw-toast .ant4-notification-notice-description) {
  margin-right: 30px;
}

:global(.ws-tooltip .ant4-tooltip-inner) {
  border-radius: 8px;
  padding: 8px 10px;
}

:global(.ws-message .ant4-message-custom-content) {
  display: flex;
  align-items: center;
}
